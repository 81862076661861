class Search {
  constructor(el, overlays) {
    this.el = el

    if (this.el) {
      this.id = this.el.id
      this.form = this.el.querySelector('[data-search-form]');
      this.field = this.el.querySelector('[data-search-field]');

      if (this.id === 'search-standalone') {
        this.initFocus();
      } else if (this.id === 'search-widget') {
        this.initWidgetFocus();

        // close search widget on topbar unpin and submenu open
        document.addEventListener('topbar-unpinned', this.closeSearchWidgetOverlay.bind(this, overlays));
        document.addEventListener('menu-level2-opened', this.closeSearchWidgetOverlay.bind(this, overlays));
      }
    }
  }

  // Focus on search input
  initFocus() {
    if (this.field) {
      this.field.focus();
    }
  }

  initWidgetFocus() {
    document.addEventListener("overlay-opened", (event) => {
      if (event.detail.id === 'search-widget' && this.field) {
        this.field.focus();
      }
    })
  }

  closeSearchWidgetOverlay(overlays) {
    if (overlays) {
      const searchWidgetOverlay = overlays.overlays.find(overlay => overlay.id === 'search-widget');
      if (searchWidgetOverlay) {
        searchWidgetOverlay.close()
      }
    }
  }
}

export default class Searches {
  constructor(overlays) {
    document.addEventListener('DOMContentLoaded', (e) => {
      this.searches = [...document.querySelectorAll('[data-search]')].map((el) => {
        return new Search(el, overlays);
      });
    });
  }
}
