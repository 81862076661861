export default {
  labelIdle: 'Trascina e rilascia i tuoi file oppure <span class = "filepond--label-action"> carica <span>',
  labelInvalidField: "Il campo contiene dei file non validi",
  labelFileWaitingForSize: "Aspettando le dimensioni",
  labelFileSizeNotAvailable: "Dimensioni non disponibili",
  labelFileLoading: "Caricamento",
  labelFileLoadError: "Errore durante il caricamento",
  labelFileProcessing: "Caricamento",
  labelFileProcessingComplete: "Caricamento completato",
  labelFileProcessingAborted: "Caricamento cancellato",
  labelFileProcessingError: "Errore durante il caricamento",
  labelFileProcessingRevertError: "Errore durante il ripristino",
  labelFileRemoveError: "Errore durante l'eliminazione",
  labelTapToCancel: "tocca per cancellare",
  labelTapToRetry: "tocca per riprovare",
  labelTapToUndo: "tocca per ripristinare",
  labelButtonRemoveItem: "Elimina",
  labelButtonAbortItemLoad: "Cancella",
  labelButtonRetryItemLoad: "Ritenta",
  labelButtonAbortItemProcessing: "Camcella",
  labelButtonUndoItemProcessing: "Indietro",
  labelButtonRetryItemProcessing: "Ritenta",
  labelButtonProcessItem: "Carica",
  labelMaxFileSizeExceeded: "Il peso del file è eccessivo",
  labelMaxFileSize: "Il peso massimo del file è {filesize}",
  labelMaxTotalFileSizeExceeded: "Dimensione totale massima superata",
  labelMaxTotalFileSize: "La dimensione massima totale del file è {filesize}",
  labelFileTypeNotAllowed: "File non supportato",
  fileValidateTypeLabelExpectedTypes: "Aspetta {allButLastType} o {lastType}",
  imageValidateSizeLabelFormatError: "Tipo di immagine non compatibile",
  imageValidateSizeLabelImageSizeTooSmall: "L'immagine è troppo piccola",
  imageValidateSizeLabelImageSizeTooBig: "L'immagine è troppo grande",
  imageValidateSizeLabelExpectedMinSize: "La dimensione minima è {minWidth} × {minHeight}",
  imageValidateSizeLabelExpectedMaxSize: "La dimensione massima è {maxWidth} × {maxHeight}",
  imageValidateSizeLabelImageResolutionTooLow: "La risoluzione è troppo bassa",
  imageValidateSizeLabelImageResolutionTooHigh: "La risoluzione è troppo alta",
  imageValidateSizeLabelExpectedMinResolution: "La risoluzione minima è {minResolution}",
  imageValidateSizeLabelExpectedMaxResolution: "La risoluzione massima è {maxResolution}",
};
