//
// Main entry file
// --------------------------------------------------

// Webpack entries
// Images
import './webpack/require-img';
// SVGs
import './webpack/require-svg';

// SVG <use> polyfill
import 'svgxuse';

// Manually load some polyfills based on `useBuiltIns: usage`
Array.from;
Object.assign;

// JS module imports
import './modules/object-fit-images';
import './modules/scrollbar';
import Overlays from './modules/overlays';
import Menues from './modules/menu';
import Panels from './modules/panels';
import Selects from './modules/selects';
import './modules/fileupload';
import Inputs from './modules/inputs';
import Searches from './modules/search';
import Alert from './modules/alert';
import './modules/product-offers';
import SlideoutBanners from './modules/slideout-banner';

new Selects();
const overlays = new Overlays();
new Menues();
new Panels();
new Inputs();
new Searches(overlays);
new Alert();
new SlideoutBanners();
