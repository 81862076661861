class Alert {
  constructor(el) {
    this.el = el;
    this.initRemovers();

    return this;
  }

  initRemovers() {
    this.removers = [...document.querySelectorAll(`[data-alert-remove]`)];

    // Removers
    this.removers.forEach((remover, idx) => {
      remover.addEventListener('click', (e) => {
        this.remove();
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.blur();
        return false;
      }, false);
    });
  }

  // Removes alert
  remove() {    
    this.el.parentNode.removeChild(this.el);
  }
}


export default class Alerts {
  constructor() {
    document.addEventListener('DOMContentLoaded', (e) => {
      this.alert = [...document.querySelectorAll('[data-alert]')].map((el) => {
        return new Alert(el);
      });
    });
  }
}
