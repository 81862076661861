// Selection logic for product offers
document.addEventListener('DOMContentLoaded', () => {
  const productOffers = [...document.querySelectorAll("[data-product-offer]")];
  productOffers.forEach(offer => {
    const checkbox = offer.querySelector("input[type='checkbox']");
    if (checkbox) {
      // Set up row click
      offer.addEventListener("click", (e) => {
        checkbox.checked = !checkbox.checked;

        const event = new Event("change");
        checkbox.dispatchEvent(event);
      })

      // Prevent checkbox click propagation
      checkbox.addEventListener("click", (e) => {
        e.preventDefault()
        e.stopPropagation()
      });

      // Listen to checkbox change to update row
      checkbox.addEventListener("change", () => checkbox.checked
      ? offer.classList.add("is-selected")
      : offer.classList.remove("is-selected"))
    }
  })
});
