import Cookies from 'js-cookie'
import Util from './util';

class SlideoutBanner {
  constructor(el) {
    this.el = el;
    this.banner = this.el.querySelector("[data-slideout-banner-banner]");
    this.cookieName = "slideoutBannerClosed";
    this.defaultCookieLifetime = 365;
    if (this.banner) {
      this.initCookie();
      this.initClosers();
    }

    return this;
  }

  initCookie() {
    this.cookieLifetime = parseInt(this.banner.dataset.slideoutBannerCookieLifetime) || this.defaultCookieLifetime
  }

  setCookie() {
    Cookies.set(this.cookieName, '1', { expires: this.cookieLifetime })
  }

  initClosers() {
    this.closers = [...document.querySelectorAll(`[data-slideout-banner-close]`)];

    // Closers
    this.closers.forEach((closer) => {
      closer.addEventListener('click', (e) => {
        this.close();
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.blur();
        return false;
      }, false);
    });
  }

  // Closes banner
  close() {
    this.el.classList.add("is-hidden");
    this.setCookie();
    Util.triggerEvent('topbar-recalculate-height');
  }
}


export default class SlideoutBanners {
  constructor() {
    document.addEventListener('DOMContentLoaded', (e) => {
      this.slideoutBanners = [...document.querySelectorAll('[data-slideout-banner]')].map((el) => {
        return new SlideoutBanner(el);
      });
    });
  }
}
