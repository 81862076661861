import * as FilePond from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import de_DE from './filepond-locales/de-de.js';
import it_IT from './filepond-locales/it-it.js';
import fr_FR from './filepond-locales/fr-fr.js';

FilePond.registerPlugin(FilePondPluginFileValidateSize);

document.addEventListener('DOMContentLoaded', () => {
  // get input elements to be enhanced
  const inputElement = document.querySelector('[data-file-upload]');

  if (inputElement) {
    // i18n
    let i18n = {}; // default: english (no labels provided)
    switch (document.documentElement.lang) {
      case 'de':
        i18n = de_DE;
        break;
      case 'it':
        i18n = it_IT;
        break;
      case 'fr':
        i18n = fr_FR;
        break;
    }

    // get server endpoint
    const server = inputElement.dataset.fileUploadServer;

    // Get possibly uploaded files
    let uploadedFiles = inputElement.dataset.fileUploadUploaded;
    const files = ((uploadedFiles && uploadedFiles.split(',')) || []).map(file => ({
      // the server file reference
      source: file,
      // set type to local to indicate an already uploaded file
      options: {
        type: 'local'
      }
    }))

    FilePond.setOptions({
      server,
      ...i18n,

      // file size validation options
      maxFileSize: '4MB',
    });

    const pond = FilePond.create( inputElement, {
      files,
      server
    });
  }
});
